import {
	runWhenAccessReturns,
} from '@forbes/fbs-tracking';
import {
	getCookie,
} from '../../../shared/cookieUtils';

// Global state is necessary since Piano events can only seem to be registered once for this setup
const currentObservables = [];
const timeouts = {};

// Resources to unpause ads/video for on hard paywall
const hardPaywallResourceAccesses = [
	'RZZFK7H', 'R880NB6', 'RA6L55F', 'RVEX5N5', 'RETBQ1H', 'R8W03AS',
	'RKPEVDB', 'RXCC3NS', 'RKA20OK', 'RZYU584', 'R3PNAHU', 'RSKL92C',
	'MARKETPLACE', 'PRESS2020', 'VENDORS2020',
];

/**
 * Checks if the current article is running on a premium paywall
 * @returns True if premium paywalled, false otherwise
 */
const isContentPremium = () => window.forbes['simple-site'].tracking.contentPaywall === 'premium';

/**
 * Checks if the current user is logged into Bertie
 * @returns True if user is logged into Bertie
 */
const isBertieUserLoggedIn = () => window.forbes['simple-site'].tracking.login === 'true';

const doesUserHaveAccess = (accesses = '') => {
	if (!accesses || typeof accesses !== 'string') {
		return 'unknown';
	}
	let hasAccessToContent = false;
	for (let i = 0; i < hardPaywallResourceAccesses.length; i++) {
		if (accesses.indexOf(hardPaywallResourceAccesses[i]) > -1) {
			hasAccessToContent = true;
		}
	}
	return hasAccessToContent;
};

const unpause = (accesses = '') => {
	if (!isBertieUserLoggedIn() && isContentPremium()) {
		const access = doesUserHaveAccess(accesses);

		if (access === 'unknown') {
			return;
		}

		if (!access) {
			clearTimeout(timeouts.unpauseTimeout);
			return;
		}
	}

	while (currentObservables.length) {
		currentObservables.shift().forEach((observable) => observable.unpause());
	}
	clearTimeout(timeouts.unpauseTimeout);
};

const registerEvents = (callback, timeoutName) => {
	if (!window.tp) {
		window.tp = [];
	}
	const {
		tp,
	} = window;

	// Handle normal Piano meter events
	tp.push(['addHandler', 'meterActive', callback]);
	tp.push(['addHandler', 'meterInactive', callback]);
	tp.push(['addHandler', 'meterError', callback]);
	tp.push(['addHandler', 'meterExpired', () => clearTimeout(timeouts[timeoutName])]);
	runWhenAccessReturns((access) => {
		if (isContentPremium()) {
			callback(access);
		}
	});

	// Fallback for when Piano decides to not fire any of the above events because the experience didn't fire
	// Since this happens every time experiences are processed it doubles up with the above events sometimes
	tp.push(['addHandler', 'experienceExecute', (results) => {
		const resultEvents = (((results || {}).result || []).events || []).map((event = {}) => event.eventType);
		if (!resultEvents.includes('meterExpired')) {
			callback();
		} else {
			clearTimeout(timeouts[timeoutName]);
		}
	}]);
};

/**
 * Checks if the page meter is expiring or already expired
 * @returns True if expiring or expired, false otherwise
 */
const isMeterExpiring = () => {
	const meterCookie = (getCookie(document.cookie, '_pc_meter_expiration') || '').split('=') || [];
	return meterCookie.length && ['expiring', 'expired'].includes(meterCookie[1]);
};

let meterEventsRegistered = false;
/**
 * Prevents observables from firing when a paywall event is expired.
 * Delays observables when paywall is about to expire until expiration status is confirmed
 * @param {Array<Observable>} observables
 */
const runAfterMeterVerified = (observables) => {
	if (!isBertieUserLoggedIn() && (isMeterExpiring() || isContentPremium())) {
		// Fallback to restart observables in the event something goes horribly wrong
		clearTimeout(timeouts.unpauseTimeout);
		timeouts.unpauseTimeout = setTimeout(unpause, 30000);

		currentObservables.push(observables);
		currentObservables.forEach((batchedObservables) => batchedObservables.forEach((observable) => observable.pause()));

		if (!meterEventsRegistered) {
			registerEvents(unpause, 'unpauseTimeout');
			meterEventsRegistered = true;
		}
	}
};

let videoEventsRegistered = false;
const delayedVideos = [];

/**
 * Reattaches previously detached videos
 */
const reattachVideos = (accesses = '') => {
	if (!isBertieUserLoggedIn() && isContentPremium()) {
		const access = doesUserHaveAccess(accesses);

		if (access === 'unknown') {
			return;
		}

		if (!access) {
			clearTimeout(timeouts.videoTimeout);
			return;
		}
	}

	let detachedVideo = delayedVideos.shift();
	while (detachedVideo) {
		detachedVideo.parent.insertBefore(detachedVideo.video, detachedVideo.filler);
		detachedVideo.parent.removeChild(detachedVideo.filler);
		detachedVideo = delayedVideos.shift();
	}
};

/**
 * Prevents autoplay videos from playing until paywall callbacks complete
 * @param {Object} article Current article to process
 */
const handleAutoplayVideos = (article) => {
	if (!isBertieUserLoggedIn() && (isMeterExpiring() || isContentPremium())) {
		const videos = article.element.querySelectorAll(article.videoSelector || 'fbs-video[autoplay],amp-brightcove[data-param-autoplay="true"]');

		clearTimeout(timeouts.videoTimeout);

		if (videos.length === 0) {
			return;
		}

		// Fallback to restart videos in the event something goes horribly wron
		timeouts.videoTimeout = setTimeout(reattachVideos, 30000);

		videos.forEach((video) => {
			const filler = document.createElement('div');
			filler.className = 'video-placeholder';
			delayedVideos.push({
				parent: video.parentElement,
				next: video.nextSibling,
				filler,
				video,
			});
			video.parentElement.insertBefore(filler, video.nextSibling);
			video.parentElement.removeChild(video);
		});

		if (!videoEventsRegistered) {
			registerEvents(reattachVideos, 'videoTimeout');
			videoEventsRegistered = true;
		}
	}
};

const clearPaywallQueues = () => {
	while (currentObservables.length) {
		currentObservables.shift().forEach((observable) => {
			observable.clear();
		});
	}
	while (delayedVideos.length) {
		delayedVideos.shift();
	}
};

export {
	clearPaywallQueues,
	runAfterMeterVerified,
	handleAutoplayVideos,
	isMeterExpiring,
};
