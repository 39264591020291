import {
	getCookie,
} from '../../../shared/cookieUtils';
import universalHeader from './universal-header';

/**
 * OpenWeb’s single sign-on (SSO) process.
 */
export default function startSSO() {
	let token = getCookie(document.cookie, '__utp');
	if (token) {
		token = token.split('=')[1].trim();
		window.SPOTIM.startSSOForProvider({ provider: 'piano', token }).then((userData) => {
			// userInfo Is needed so that openWeb wouldn't need to run the full handshake (logout/login) process on every pageview.
			// eslint-disable-next-line no-unused-vars
			const userInfo = userData;
		}).catch((reason) => {
			console.error(reason);
		});
	}
}

/**
 * Check if SPOTIM.logout exists
 * @returns boolean true if SPOTIM.logout is available.
 */
function logOutOpenWeb() {
	if (window.SPOTIM && window.SPOTIM.logout) {
		return true;
	}
	return false;
}

// login in a user
if (window.SPOTIM && window.SPOTIM.startSSOForProvider) {
	startSSO();
} else {
	document.addEventListener('spot-im-api-ready', startSSO, false);
}

// sync login for forbes with openWeb
document.addEventListener('fbs-piano-init', () => {
	const pianoId = universalHeader.getPianoId();
	if (pianoId.isUserValid()) {
		document.addEventListener('spot-im-api-ready', startSSO, false);
	} else if (logOutOpenWeb()) {
		window.SPOTIM.logout();
	}
});
