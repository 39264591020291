import { fireGAEvent } from './tracking';

let pianoNewsletterData;

/**
 * Set and return template offer object.
 * @param {String} containerSelector value assign to containerSelector.
 * @returns {Object}
 */
const setTemplateOffer = (containerSelector, type) => {
	const pianoModalIds = JSON.parse(pianoNewsletterData.getAttribute('piano-modal-ids'));
	const pianoModal = pianoModalIds[type];

	const templateOffer = {
		offerId: pianoModal.offerId,
		templateId: pianoModal.templateId,
		displayMode: 'inline',
		containerSelector,
	};

	if (pianoModal.templateVariantId) {
		templateOffer.templateVariantId = pianoModal.templateVariantId;
	}
	return templateOffer;
};

/**
 * show Free Newsletter Template offer.
 * @param {Boolean} isFreeNewsLetter if the template type is FreeNewsletter
 * @param {Object} templateOffer template offer object from API.
 */
const setFreeNewsletterOffer = (isFreeNewsLetter, templateOffer) => {
	if (isFreeNewsLetter) {
		const { tp } = window;
		Object.keys(templateOffer).forEach((key) => (templateOffer[key] === undefined ? delete templateOffer[key] : {}));
		tp.template.show(templateOffer);
	}
};

/**
 * Initialize offer event when fbs-piano-init is set.
 */
const newsletterEvent = (containerSelector, type) => {
	pianoNewsletterData = document.querySelector('input.piano-newsletter');

	if (!pianoNewsletterData) {
		return;
	}

	const { tp } = window;
	const isSubscribed = JSON.parse(pianoNewsletterData.getAttribute('user-subscription'));

	if (tp && (isSubscribed || {}).error) {
		const templateOffer = setTemplateOffer(containerSelector, type);
		const { isFreeNewsLetter = false } = window.forbes['simple-site'];
		setFreeNewsletterOffer(isFreeNewsLetter, templateOffer);

		// Init offer
		tp.push(['init', () => {
			tp.offer.show(templateOffer);
			// Fire event of which piano offer loads
			fireGAEvent({
				eventCategory: 'Piano',
				eventAction: 'NewslettersPaywall',
				eventLabel: 'Shown',
			});
		}]);
	}
};

/**
 * newsletterOffer used by article and contributor.
 * @param {*} containerSelector used to populate the templateOffer
 * @param {*} type determine the object (lander or article) to populate templateOffer
 */
const newsletterOffers = (containerSelector, type) => {
	if (document.querySelector(containerSelector)) {
		document.addEventListener('fbs-piano-init', () => newsletterEvent(containerSelector, type));
	}
};

export default newsletterOffers;
