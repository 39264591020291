const React = require('react');
const { Subnav, SubnavItem } = require('@forbes/fbs-components');
const { useReactComponent } = require('../../../../app/shared/componentLibraryService');
const { reactComponentsTracking } = require('../../shared/react-tracking');
const { fdaLogo } = require('../../../svg');

const handleSubnavTracking = (label) => () => reactComponentsTracking('Forbes Digital Assets', 'Click', label);
const getSubnavItems = (subnavItemsData = []) => (subnavItemsData.map((subnavItem) => ({ name: subnavItem.title || '', href: subnavItem.url || '' })));

const getSubnav = (subnavItemsData) => {
	const formattedSubnavItems = getSubnavItems(subnavItemsData);
	const svgContainer = React.createElement('span', { dangerouslySetInnerHTML: { __html: fdaLogo } });
	const subnavItems = formattedSubnavItems.map((item) => (
		React.createElement(
			SubnavItem,
			{ ...item, key: item.name, onClick: handleSubnavTracking(item.name) },
		)));

	return useReactComponent(Subnav, {
		logo: svgContainer,
	}, subnavItems);
};

module.exports = {
	getSubnav,
};
