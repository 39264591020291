import { isEurope } from './clientConfigService';

/**
 * Checks if the GDPR cookie has been added if in Europe
 */
const gdprCheck = () => (
	new Promise((resolve) => {
		if (isEurope && document.cookie.indexOf('notice_gdpr_prefs=') < 0) {
			let intervalCheck = 0;

			const gdprInterval = setInterval(() => {
				// We check for the cookie or wait 7 seconds
				if (document.cookie.indexOf('notice_gdpr_prefs=') >= 0 || intervalCheck > 35) {
					clearInterval(gdprInterval);
					resolve(true);
				} else {
					intervalCheck++;
				}
			}, 200);
		} else {
			resolve(true);
		}
	})
);

// The status of user consent
const isDisapproved = document.cookie.match(/notice_gdpr_prefs=/) && !document.cookie.match(/notice_gdpr_prefs=0,1,2/);

export {
	gdprCheck as default,
	isDisapproved,
};
