import { fireGAEvent } from './tracking';
import checkStatus from './checkStatus';
import { isChina, isEurope } from './clientConfigService';

const { isMobile } = require('./is-mobile');
const { getCookieValue } = require('../../../shared/cookieUtils');
const {
	createAckCookie,
	updateAckCookie,
	acceptAckCookie,
} = require('./acknowledgementCookie');

function showOpenWeb() {
	return (!document.cookie.match(/notice_gdpr_prefs=/) || !!document.cookie.match(/notice_gdpr_prefs=0,1,2/))
		&& ((!isChina && !isEurope) || !!document.cookie.match(/notice_gdpr_prefs=0,1,2/));
}

/**
 * Update HTML for openWeb commenting counter
 * @param {Object} data contains number of comments data.
 * @param {Number} streamArticleIndex Current stream position of the next article in the stream.
 * @param {String} articleId current article id.
 */
function updateCommentingDivHTML(data, streamArticleIndex, articleId) {
	const commentsCountDiv = document.querySelector(`.spot-im-replies-count[onclick="location.href='#open-web-${streamArticleIndex}'"]`);

	if (commentsCountDiv && data) {
		const countTotal = data.messages_count[articleId] || 0;
		const messagesCountDiv = document.createElement('div');
		messagesCountDiv.className = 'messages-count';
		const link = document.createElement('a');
		link.innerText = countTotal;
		messagesCountDiv.appendChild(link);
		commentsCountDiv.appendChild(messagesCountDiv);
	}
}

/**
 * Get number of comments for article from openWeb and update html.
 * @param {Number} streamArticleIndex Current stream position of the next article in the stream.
 * @param {String} articleId current article id.
 */
function updateNumberOfCommentsForArticle(streamArticleIndex, articleId) {
	const { isProd = false } = window.forbes['simple-site'];
	const spotId = isProd ? 'sp_qBnNq7ll' : 'sp_EgBmvxVv';

	if (streamArticleIndex === 0 || !articleId) {
		articleId = window.forbes['simple-site'].articleId;
	}

	if (articleId && articleId.startsWith('blogAndPostId')) {
		articleId = `content_${articleId.split('-')[1]}`;
	}

	fetch(`https://open-api.spot.im/v1/messages-count?spot_id=${spotId}&posts_ids=${articleId}`)
		.then((response) => {
			checkStatus(response);
			response.json()
				.then((json) => {
					// call a function to update the html
					updateCommentingDivHTML(json, streamArticleIndex, articleId);
				});
		})
		.catch((error) => console.error(error));
}

function getGADataAndTooltip(streamArticleIndex, anchorButton) {
	const toolTipText = document.querySelector(`#article-stream-${streamArticleIndex} .tooltip-text:not(.following)`);

	const GAData = {
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: 'Commenting Icon',
	};

	if (anchorButton) {
	// Click tracking for commenting icon
		anchorButton.addEventListener('click', () => {
			fireGAEvent(GAData);
		});
	}

	// animate toolTip after 5sec
	if (toolTipText) {
		const tooltipBtn = toolTipText.querySelector(':not(.following) .tooltip-button');
		const ackCookie = getCookieValue(document.cookie, 'cmtAck');
		const ackData = ackCookie ? JSON.parse(ackCookie) : {};
		const isSubscriber = ((window.forbes || {})['simple-site'] || {}).isSubscriber;

		if (!ackCookie || (!isSubscriber && !ackData.ack) || (isSubscriber && ackData.session === 3)) {
			createAckCookie('cmtAck');

			setTimeout(() => {
				toolTipText.classList.add('tooltip-show');
				anchorButton?.classList.add('hover-show');
			}, 5000);
		}

		if (isSubscriber && ackData.session < 3 && streamArticleIndex === 0) {
			updateAckCookie('cmtAck', ackData.session);
		}

		toolTipText.addEventListener('click', () => {
			toolTipText.classList.remove('tooltip-show');
			anchorButton?.classList.remove('hover-show');
		});

		if (tooltipBtn) {
			tooltipBtn.addEventListener('click', () => {
				acceptAckCookie('cmtAck');
			});
		}
	}
}

function openWebOnLoad() {
	window.owActionQueue = window.owActionQueue || function reload(action) {
		(window.owActionQueue.queue = window.owActionQueue.queue || []).push(action);
	};
	window.owActionQueue({
		actionName: 'initApp',
		actionParams: [],
	});
}

function openWebFunctionality(streamArticleIndex, anchorButton, openWebPlaceHolder) {
	const openWebCloseContainer = document.querySelector(`#article-stream-${streamArticleIndex} .openWeb-commenting`);
	const openWebOverlay = document.querySelector(`#article-stream-${streamArticleIndex} .openWeb-overlay`);
	const closeButton = document.querySelector(`#article-stream-${streamArticleIndex} .close--icon`);
	const html = document.querySelector('html');

	const GAData = {
		eventCategory: 'Template Area Interaction',
		eventAction: 'click',
		eventLabel: 'Commenting Close',
	};

	anchorButton.addEventListener('click', () => {
		openWebOverlay?.classList.add('view');
		openWebPlaceHolder.classList.add('openWeb-commenting--animation');
		openWebCloseContainer?.classList.add('openWeb-commenting--animation');
		html.style.setProperty('overflow', 'hidden');
		openWebOnLoad();
	});

	closeButton.addEventListener('click', () => {
		openWebOverlay?.classList.remove('view');
		html.style.removeProperty('overflow');
		fireGAEvent(GAData);
	});
}

function actionOpenWeb(streamArticleIndex, naturalId) {
	if (!showOpenWeb()) {
		return;
	}

	const openWebPlaceHolder = document.querySelector(`#article-stream-${streamArticleIndex} .openWeb-commenting`);
	const anchorButton = document.querySelector(`#article-stream-${streamArticleIndex} .open-web_anchor--link`);

	// Adds data for counter
	updateNumberOfCommentsForArticle(streamArticleIndex, naturalId);

	getGADataAndTooltip(streamArticleIndex, anchorButton);

	if (openWebPlaceHolder && isMobile) {
		openWebFunctionality(streamArticleIndex, anchorButton, openWebPlaceHolder);
	}
}

export {
	showOpenWeb,
	actionOpenWeb,
};
