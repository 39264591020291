import { isMobile } from '../../shared/is-mobile';
import { showOpenWeb } from '../../shared/openWebUtils';

function addOpenwebScript(article, index) {
	const openwebDiv = article.querySelector('.openWeb-commenting');

	if (openwebDiv && !openwebDiv.hasChildNodes() && showOpenWeb()) {
		if (index === 0) {
			const script = document.createElement('script');

			script.src = `https://launcher.spot.im/spot/${openwebDiv.dataset.id}`;
			script.dataset.spotimModule = 'spotim-launcher';
			script.dataset.messagesCount = 3;

			openwebDiv.insertAdjacentElement('beforebegin', script);
		}

		const anchor = article.querySelector('.open-web_anchor.hidden');
		const anchorLink = article.querySelector('.open-web_anchor--link');
		if (!isMobile) {
			anchorLink.addEventListener('click', () => {
				article.querySelector('[id*="open-web-"]').scrollIntoView({ behavior: 'smooth', block: 'end' });
			});
		}

		if (anchor) {
			anchor.classList.remove('hidden');
		}
	}
}

document.addEventListener('DOMContentLoaded', addOpenwebScript(document, 0));

export default addOpenwebScript;
