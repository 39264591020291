import { fireGAEvent } from './tracking';

const { getCookieValue } = require('../../../shared/cookieUtils');
const {
	createAckCookie,
	updateAckCookie,
	acceptAckCookie,
} = require('./acknowledgementCookie');

window.addEventListener('DOMContentLoaded', () => {
	const followToolTip = document.querySelector('.tooltip-text.following');

	// animate toolTip after 5sec
	if (followToolTip) {
		const tooltipBtn = followToolTip.querySelector('.following .tooltip-button');
		const flwCookie = getCookieValue(document.cookie, 'flwAck');
		const flwData = flwCookie ? JSON.parse(flwCookie) : {};
		const isSubscriber = ((window.forbes || {})['simple-site'] || {}).isSubscriber;

		if (!flwCookie || (!isSubscriber && !flwData.ack) || (isSubscriber && flwData.session === 3)) {
			setTimeout(() => {
				createAckCookie('flwAck');
				if (!document.querySelector('.tooltip-show.tooltip-text:not(.following)')) {
					followToolTip.classList.add('tooltip-show');
				}
			}, 5000);
		}

		if (isSubscriber && flwData.session < 3) {
			updateAckCookie('flwAck', flwData.session);
		}

		followToolTip.addEventListener('click', () => {
			followToolTip.classList.remove('tooltip-show');
		});

		if (tooltipBtn) {
			tooltipBtn.addEventListener('click', () => {
				fireGAEvent({
					eventAction: 'click',
					eventLabel: 'Following Tooltip Accept',
				});
				acceptAckCookie('flwAck');
			});
		}
	}
});
