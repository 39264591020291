/**
* Accounts for the shifting effect that is caused by the hidden scrollbar when a modal is open
*/
function handlePageShiftOnModalOpen() {
	const style = document.createElement('style');
	document.getElementsByTagName('head')[0].appendChild(style);
	const body = document.querySelector('body');
	const scrollbarWidth = window.innerWidth - body.clientWidth;
	style.innerHTML = `.tp-modal-open { padding-right: ${scrollbarWidth}px; } .tp-modal-open .universal-header {padding-right: ${scrollbarWidth}px;}`;
}

document.addEventListener('DOMContentLoaded', handlePageShiftOnModalOpen);
