/**
 * create acknowledgment cookie
 * @param {String} cookieName cookie name
 */
function createAckCookie(cookieName) {
	document.cookie = `${cookieName}=${JSON.stringify({ ack: false, session: 0 })};path=/`;
}

/**
 * update acknowledgment cookie sessions
 * @param {String} cookieName cookie name
 * @param {Number} session current number of sessions
 */
function updateAckCookie(cookieName, session) {
	document.cookie = `${cookieName}=${JSON.stringify({ ack: false, session: session + 1 })};path=/`;
}

/**
 * create acknowledgment accepted cookie
 * @param {String} cookieName cookie name
 */
function acceptAckCookie(cookieName) {
	document.cookie = `${cookieName}=${JSON.stringify({ ack: true })};path=/`;
}

export {
	createAckCookie,
	updateAckCookie,
	acceptAckCookie,
};
