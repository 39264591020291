/**
* Handles simple element creation.
@param {string} [type='div'] The type of element you'd like to create.
@param {Object} [attributes={}] The attributes you'd like to add to new element.
@return {Element} Returns an element with all attributes added.
*/
function createSimpleElement(type = 'div', attributes = {}) {
	const simpleElement = document.createElement(type);
	Object.keys(attributes).forEach((key) => simpleElement.setAttribute(key, attributes[key]));
	return simpleElement;
}

/**
 * Loop through the elements and toggle className.
 * @param {Object} The DOM element.
 * @param {string} The element class name.
 * @param {string} The action to apply to element classList.
 */
const updateElementClass = (element, className, action) => {
	if (element) {
		element.classList[action](className);
	}
};

export {
	createSimpleElement,
	updateElementClass,
};
